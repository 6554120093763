import axios from "axios";
import {
  ajaxGet,
  ajaxPost,

} from "./request";
import { url } from "./url";


export const loadPackagingListApi = (params) =>ajaxGet({ url: `packaging-fee/list`, params });
export const newPackagingFeeApi = (params) =>ajaxPost({ url: `packaging-fee/new`, params });
export const managePackagingFeeApi = (params) =>ajaxPost({ url: `packaging-fee/manage/status`, params });