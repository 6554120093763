import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadCategoyListApi = (params={}) =>ajaxGet({url:'category/list', params})
export const addCategoyApi = (params={}) =>ajaxPost({url:'mall/category/add', params})
export const editCategoyApi = (params={}) =>ajaxPost({url:'mall/category/edit', params})
export const manageStatusCategoyApi = (params={}) =>ajaxPost({url:'mall/category/manage/status', params})


export const loadBreedsListApi = (params={}) =>ajaxGet({url:'breed/list', params})
export const addBreedsApi = (params={}) =>ajaxPost({url:'mall/breeds/add', params})
export const editBreedsApi = (params={}) =>ajaxPost({url:'mall/breeds/edit', params})
export const manageStatusBreedsApi = (params={}) =>ajaxPost({url:'mall/breeds/manage/status', params})

// export const stationsEditApi = (params={}) =>ajaxPost({url:'mall/stations/edit/', params})
// export const stationsAddApi = (params={}) =>ajaxPost({url:'mall/stations/add/', params})
// export const manageShopStatusApi = (params={}) =>ajaxPost({url:'shop/manage/status/', params})
// export const auditShopStatusApi = (params={}) =>ajaxPost({url:'shop/audit/', params})