<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="名称：">
                    <el-input size="default" v-model.trim="formInline.name" maxlength="60" clearable placeholder="名称"
                        @change="search" style="width:200px"></el-input>
                </el-form-item>
                <!--                <el-form-item label="创建时间：">-->
                <!--                    <el-date-picker-->
                <!--                            style="width:100% !important;"-->
                <!--                            v-model="timers"-->
                <!--                            size="small"-->
                <!--                            type="datetimerange"-->
                <!--                            @change="timeChange"-->
                <!--                            range-separator="至"-->
                <!--                            start-placeholder="开始日期"-->
                <!--                            end-placeholder="结束日期">-->
                <!--                    </el-date-picker>-->
                <!--                </el-form-item>-->
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button size="default" icon="Plus" @click="addModule" type="primary"
                        v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column>
            <el-table-column width="150" prop="pos_type" label="展示位置"></el-table-column>
            <el-table-column width="800" prop="content" label="展示内容"></el-table-column>
            <!-- <el-table-column width="150" prop="status" label="使用状态"></el-table-column> -->
			<el-table-column width="150" label="使用状态">
			  <template #default="scope">
				  <el-button v-if="scope.row.status" type="primary" round size="small" @click="mangeStatus(scope.row.id,'stop')">使用中</el-button>
				  <el-button v-if="!scope.row.status" type="warning" round size="small" @click="mangeStatus(scope.row.id,'start')">未使用</el-button>
			    <!-- <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
			               @change="changeStatus(scope.row)">
			    </el-switch> -->
			  </template>
			</el-table-column>
            <!-- <el-table-column min-width="180" prop="desc" show-overflow-tooltip label="内容">
                <template #default="scope">
                    <div v-html="customEllipsis(scope.row.desc)" class="ellipsis"></div>
               </template>
            </el-table-column> -->
           
            <el-table-column label="操作" fixed="right" >
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                        v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
                    <span class="table-operate-btn" @click="mangeStatus(scope.row.id, 'del')"
                        v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <tips-module ref="addModuleFlag" @refreshData="getData"></tips-module>
    </div>
</template>
<script>

import tipsModule from "./components/tipsModule";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { fmallIconindex, fmallIconindexDelete } from '@/api/api'
import { loadTipsListApi,manageTipsStatusApi } from '@/api/tips'
export default {
    components: {
        Pagination,
        tipsModule
    },
    name: 'indexIconManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '是' },
                { id: 0, name: '否' }
            ],
            tableData: []
        }
    },
    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        //当渲染的文字超出10字后显示省略号
        customEllipsis(value) {
            value = value.replace(/<.*?>/ig, "")       //把v-html的格式标签替换掉
            if (!value) return ""
            if (value.length > 10) {
                return value.slice(0, 10) + "..."
            }
            return value
        },
        addModule() {
            this.$refs.addModuleFlag.addModuleFn(null, '新增')
        },
        changeStatus(row) {
            // console.log(row,'row----')
        },
		mangeStatus(id,act){
			let msg=""
			if(act=='start'){
				msg='确定启用该引导语吗'
			}
			if(act=='stop'){
				msg='确定停用该引导语吗？'
			}
			if(act=='del'){
				msg='确定删除该引导语吗？请谨慎操作！'
			}
			
			this.$confirm(msg,'提醒',{
				closeOnClickModal: false,
				type: "warning"
			}).then(res=>
			{
				manageTipsStatusApi({ id: id,act_type:act }).then(res => {
				  if (res.code == 1) {
				    this.$message.success(res.msg)
				    this.getData()
				  } else {
				    this.$message.warning(res.msg)
				  }
				})
			})
		},
		
        handleEdit(row, flag) {
            let vm = this
            if (flag == 'edit') {
                vm.$refs.addModuleFlag.addModuleFn(row, '编辑')
            }
            else if (flag == 'delete') {
                vm.$confirm('确定要删除选中的信息吗？', {
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(res => {
                    fmallIconindexDelete({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            loadTipsListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 1) {
                    this.tableData = res.data.data_list
                  
                    this.pageparm.total = res.data.total;
                }
            })
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }
    },
    created() {
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },
    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
