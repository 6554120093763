import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";
// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadPlatAddPriceListApi = (params={}) =>ajaxGet({url:'add-price/list', params})
export const addPlatItemApi = (params={}) =>ajaxPost({url:'add-price/add', params})
export const editPlatItemApi = (params={}) =>ajaxPost({url:'add-price/edit', params})
export const manageAddPriceStatus = (params={}) =>ajaxPost({url:'add-price/manage/status', params})


// export const stationsEditApi = (params={}) =>ajaxPost({url:'mall/stations/edit/', params})
// export const stationsAddApi = (params={}) =>ajaxPost({url:'mall/stations/add/', params})
// export const manageShopStatusApi = (params={}) =>ajaxPost({url:'shop/manage/status/', params})
// export const auditShopStatusApi = (params={}) =>ajaxPost({url:'shop/audit/', params})