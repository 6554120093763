import axios from "axios";
import {
  ajaxGet,
  ajaxPost,

} from "./request";
import { url } from "./url";


//打包费设置
export const loadTipsListApi = (params) =>ajaxGet({ url: `tips/list`, params });

export const newTipsApi = (params) =>ajaxPost({ url: `tips/new`, params });

export const manageTipsStatusApi = (params) =>ajaxPost({ url: `tips/manage/status`, params });
  