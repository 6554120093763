<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="660px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto" class="form-store">
                <el-form-item label="父级菜单：" prop="parent_id">
                    <el-cascader
                            style="width: 100%"
                            :key="isResourceShow"
                            :show-all-levels="false"
                            :options="options"
                            v-model="formData.parent_id"
                            @change="handleChange"
                            :props="{expandTrigger:'hover', checkStrictly: true ,label:'name',value:'id'}"
                            clearable></el-cascader>
                </el-form-item>
                <el-form-item label="菜单名称：" prop="name">
                    <el-input v-model.trim="formData.name" ></el-input>
                </el-form-item>
                <el-form-item label="侧边栏可见：" prop="visible">
                    <el-radio-group v-model="formData.visible" >
                        <el-radio :value="true">是</el-radio>
                        <el-radio :value="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                
                <el-form-item label="图标：" prop="icon">
<!--                    <el-input v-model.trim="formData.icon" ></el-input>-->
                    <l-y-choose-icons v-model="formData.icon"></l-y-choose-icons>
<!--                    <el-autocomplete-->
<!--                            v-model.trim="formData.icon"-->
<!--                            :fetch-suggestions="querySearch"-->
<!--                            :trigger-on-focus="false"-->
<!--                            clearable-->
<!--                            style="width: 100%"-->
<!--                            @select="handleSelect"-->
<!--                            popper-append-to-body-->
<!--                            placeholder="请输入icon">-->
<!--                        <template #default="{ item }">-->
<!--                            <div style="display: flex;align-items: center">-->
<!--                                <el-icon :size="16">-->
<!--                                    <component :is="item.value"></component>-->
<!--                                </el-icon>-->
<!--                                <div style="margin-left: 5px;">{{ item.value }}</div>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </el-autocomplete>-->
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort" :min="0" :max="9999"></el-input-number>
                </el-form-item>
                <el-form-item label="路由地址：" prop="web_path">
                    <el-input v-model.trim="formData.web_path" ></el-input>
                    <el-alert v-if="formData.parent_id>0" title="请填写xxx.vue中的name或文件名作为路由地址" type="info" show-icon/>
                    <!-- <el-alert v-if="!formData.parent" title="请填写xxx.vue中的name或文件名作为路由地址" type="info" show-icon/> -->
                </el-form-item>
                <el-form-item label="状态：" prop="status">
                    <el-radio-group v-model="formData.status" style="width: 300px">
                        <el-radio :value="true">启用</el-radio>
                        <el-radio :value="false">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
				<el-form-item label="创建按钮权限：" prop="isautopm">
				               
				<el-radio-group v-model="formData.isautopm" >
				        <el-radio :value="true">是</el-radio>
				        <el-radio :value="false">否</el-radio>
				</el-radio-group>
				    <span style="font-size: 10px;color: red;">*【否】表示创建一个目录,如一级菜单就不用创建按钮</span>
					
				<div v-if="formData.isautopm">
					<el-button @click="addButton">添加页面按钮</el-button>
					<el-table :data="button_list" row-key="name"  border style="margin-top: 10px;">
						<el-table-column width="120" prop="name" label="按钮名称">
							<template #default="scope">
							   <el-tag v-if="!scope.row.is_edit">{{scope.row.name}}</el-tag>
							   <el-input v-if="scope.row.is_edit" v-model="scope.row.name"></el-input>
							</template>
						</el-table-column>
						<el-table-column width="120" prop="value" label="权限字段">
							<template #default="scope">
							   <el-tag v-if="!scope.row.is_edit">{{scope.row.value}}</el-tag>
							   <el-input v-if="scope.row.is_edit" v-model="scope.row.value"></el-input>
							</template>
						</el-table-column>
						<el-table-column width="120" prop="method" label="请求方式">
							<template #default="scope">
							   <el-tag v-if="!scope.row.is_edit">{{scope.row.method}}</el-tag>
							   <el-input v-if="scope.row.is_edit" v-model="scope.row.method"></el-input>
							</template>
						</el-table-column>
						<el-table-column   label="操作 ">
							<template #default="scope">
								<span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" >编辑</span>
								<span class="table-operate-btn" @click="handleEdit(scope.row,'del')" >删除</span>
							</template>
							
						</el-table-column>
					</el-table>
				</div>	
				</el-form-item>
				
				
					
					
		

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {apiSystemMenu,apiSystemMenuAdd,apiSystemMenuEdit,systemMenuTree} from '@/api/api'
    import {addMenuApi,editMenuApi,loadMenuListApi,loadMenuTreeApi ,newMenuApi} from '@/api/system'
    import XEUtils from "xe-utils";
    import {menuicons} from "@/utils/menuTree.js"
    import LyDialog from "@/components/dialog/dialog";
    import LYChooseIcons from "@/components/icons/chooseIcons";
    import {deepClone} from "@/utils/util";

    export default {
        components: {LYChooseIcons, LyDialog},
        emits: ['refreshData'],
        name: "addMenu",
        data() {
            return {
                menuicons:[],
                dialogVisible:false,
                loadingSave:false,
                dialogTitle:'',
                isResourceShow:0,
                formData:{
					id:0,
                    parent:'',
                    name:'',
                    visible:true,
                    icon:'',
                    status:true,
                    sort:0,
                    web_path:'',
                    menuPermission:[],
                    isautopm:false,
                },
                inputVisible: false,
                inputValue: '',
                rules:{
                   /* parent: [
                        {required: true, message: '请选择父级菜单',trigger: 'blur'}
                    ],*/
                    name: [
                        {required: true, message: '请输入菜单名称',trigger: 'blur'}
                    ],
                    visible: [
                        {required: true, message: '请选择侧边栏是否可见',trigger: 'blur'}
                    ],
                    sort: [
                        {required: true, message: '请输入排序',trigger: 'blur'}
                    ],
                    // icon: [
                    //     {required: true, message: '请填充图标',trigger: 'blur'}
                    // ],
                    status: [
                        {required: true, message: '请选择状态',trigger: 'blur'}
                    ],
                    // web_path: [
                    //     {required: true, message: '请输入路由地址',trigger: 'blur'}
                    // ],
                    isautopm: [
                        {required: true, message: '请选择是否自动创建按钮权限',trigger: 'blur'}
                    ],
                },
                options: [],
				button_list:[
					{"id":0,"name": "新增", "value": "Create","method":"POST","is_edit":false},
					{"id":0,"name": "删除", "value": "Delete","method":"POST","is_edit":false},
					{"id":0,"name": "编辑", "value": "Update","method":"POST","is_edit":false},
					{"id":0,"name": "查询", "value": "Search","method":"GET","is_edit":false},
				]
            }
        },
        mounted() {
            this.menuicons = menuicons
        },
        methods:{
			addButton(){
				
				this.button_list.push({
					id:0,
					name:"",
					value:"",
					method:"",
					is_edit:true
				})
			},
			handleEdit(row,flag){
				
				if(flag=="edit"){
					row.is_edit=true
				}
				if(flag=="del"){
					this.button_list=this.button_list.filter(item=>item.name!=row.name)
					
				}
				
			},
            handleCloseTag(tag) {
                this.formData.menuPermission.splice(this.formData.menuPermission.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },

            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.formData.menuPermission.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            handleSelect(item){
                // console.log(item)
            },
            querySearch(queryString, cb) {
                var menuicons = this.menuicons;
                var results = queryString ? menuicons.filter(this.createFilter(queryString)) : menuicons;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (menuicons) => {
                  return (menuicons.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },

            handleChange(e) {
              
			  this.formData.parent_id=e[e.length-1]
            
            },
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
					id:0,
                    parent_id:0,
                    name:'',
                    visible:true,
                    icon:'',
                    status:true,
                    sort:0,
                    web_path:'',
                    button_list:[{"id":0,"name": "新增", "value": "Create","method":"POST","is_edit":false},
					{"id":0,"name": "删除", "value": "Delete","method":"POST","is_edit":false},
					{"id":0,"name": "编辑", "value": "Update","method":"POST","is_edit":false},
					{"id":0,"name": "查询", "value": "Search","method":"GET","is_edit":false}],
                    isautopm:1,
                }
            },
            addMenuFn(item,flag) {
                this.getsystemMenuTree()
                this.dialogVisible=true
                this.dialogTitle=flag

                //解决Cannot read property ‘level‘ of null问题
                this.options=[]
                this.isResourceShow=0
				
                if(item){
					
                    this.formData = deepClone(item)
					if (item.button_list){
						this.button_list=item.button_list
					}
					
					this.formData.parent=item.parent_id
					if (this.button_list && this.button_list.length>0){
					   this.formData.isautopm=true	
					}else{
						this.formData.isautopm=false
					}
					
					
                }
				
                
                this.formData.menuPermission=item ? item.menuPermission : []

            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        let param = {
                            ...this.formData
                        }
						if (this.formData.isautopm){
							param.button_list=this.button_list 
						}else{
							param.button_list=[]
						}
						
						
                        this.loadingSave=true
						newMenuApi(param).then(res=>{
							this.loadingSave=false
							if(res.code==1){
								this.$message.success(res.msg)
								this.handleClose()
								this.$emit('refreshData')
							}else{
								this.$message.warning(res.msg)
							}
							
						})
						
                       

                    }
                })
            },
            getsystemMenuTree() {
                var params = {
                    page:1,
                    limit:9999,
					level:0
                }
                loadMenuTreeApi(params).then(res=>{
                    ++this.isResourceShow
					
                    if(res.code == 1) {
                        let menu = [{
                            label:'-1',
                            name:'顶级菜单',
							id:0,
                            children:XEUtils.toArrayTree(res.data.data_list, { parentKey: 'parent' })
                            }]
						
                        // 将菜单列表转换为树形列表
                        this.options = menu
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
                
            }
        }
    }
</script>
<style lang="scss" scoped>
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
</style>


