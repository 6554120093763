<template>
	<div :class="{ 'ly-is-full': isFull }">
		<div class="tableSelect" ref="tableSelect">
			<el-form :inline="true" :model="formInline" label-position="left">
				<el-form-item label="优惠券名称：">
					<el-input size="default" v-model.trim="formInline.name" maxlength="60" clearable placeholder="名称"
						@change="search" style="width:200px"></el-input>
				</el-form-item>
				<!--                <el-form-item label="创建时间：">-->
				<!--                    <el-date-picker-->
				<!--                            style="width:100% !important;"-->
				<!--                            v-model="timers"-->
				<!--                            size="small"-->
				<!--                            type="datetimerange"-->
				<!--                            @change="timeChange"-->
				<!--                            range-separator="至"-->
				<!--                            start-placeholder="开始日期"-->
				<!--                            end-placeholder="结束日期">-->
				<!--                    </el-date-picker>-->
				<!--                </el-form-item>-->
				<el-form-item label=""><el-button @click="search" type="primary" icon="Search"
						v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
				<el-form-item label=""><el-button @click="handleEdit('', 'reset')"
						icon="Refresh">重置</el-button></el-form-item>
				<el-form-item label=""><el-button size="default" icon="Plus" @click="addModule" type="primary"
						v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>
				<el-form-item label=""><el-button size="default" icon="Plus" @click="handleEdit('','add-customer-coupon')" type="primary"
						v-show="hasPermission(this.$route.name, 'Create')">向用户派券</el-button></el-form-item>
			</el-form>
		</div>
		<el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage"
			style="width: 100%">
			<el-table-column  width="80" align="center" label="序号">
				<template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
			</el-table-column>
			<el-table-column width="120" prop="name" label="优惠券名称" show-overflow-tooltip></el-table-column>
			<el-table-column width="120" prop="discount_value" label="优惠金额"></el-table-column>
			<el-table-column width="120" prop="discount_value" label="使用条件">
				<template #default="scope">
					<el-tag v-if="scope.row.use_limit_type==1" >
						满{{scope.row.min_product_count}}扎可用
					</el-tag>
					<el-tag v-if="scope.row.use_limit_type==2" >
						满{{scope.row.min_product_money}}元可用
					</el-tag>
					
				</template>
			</el-table-column>
			
			<el-table-column width="80" prop="total_count" label="总数量"></el-table-column>
			<el-table-column width="90" prop="get_count" label="已领数量"></el-table-column>
			<el-table-column width="90" prop="left_count" label="剩余数量"></el-table-column>
			<el-table-column width="110"  label="可见范围">
				<template #default="scope">
					<el-tag v-if="scope.row.is_publish">用户端可见</el-tag>
					<el-tag v-if="!scope.row.is_publish">仅后台可见</el-tag>
				</template>
			</el-table-column>
			<!-- <el-table-column min-width="200" prop="desc" label="使用说明" show-overflow-tooltip></el-table-column> -->
			<el-table-column width="200" prop="desc" show-overflow-tooltip label="使用说明">
              <!--  <template #default="scope">
                    <div v-html="customEllipsis(scope.row.desc)" class="ellipsis"></div>
                </template> -->
            </el-table-column>
			<!-- <el-table-column width="170" prop="create_datetime" label="创建时间"></el-table-column> -->
			<el-table-column width="200" label="领取时段">
				<template #default="scope" style="font-size: 14px;">
					{{ scope.row.get_begin_time + " ~ " + scope.row.get_end_time }}
				</template>
			</el-table-column>
			<el-table-column width="200" label="使用时段">
				<template #default="scope">
					<div v-if="scope.row.date_type==0">{{ scope.row.use_begin_time + " ~ " + scope.row.use_end_time }}</div>
					<div v-if="scope.row.date_type==1">自领取后{{scope.row.days}}天内有效</div>
				</template>
			</el-table-column>
			<el-table-column min-width="80" label="状态">
				<template #default="scope">
					<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
						@change="changeStatus(scope.row)">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="180">
				<template #header>
					<div style="display: flex;justify-content: space-between;align-items: center;">
						<div>操作</div>
						<div @click="setFull">
							<el-tooltip content="全屏" placement="bottom">
								<el-icon><full-screen /></el-icon>
							</el-tooltip>
						</div>
					</div>
				</template>
				<template #default="scope">
					<span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
						v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
					<span class="table-operate-btn" @click="handleEdit(scope.row, 'record')">领取记录</span>
					<span class="table-operate-btn" @click="manageStatus(scope.row.id, 'force-stop')">强制报废</span>
					<span class="table-operate-btn" @click="manageStatus(scope.row.id, 'del')">删除</span>
					<!-- <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
                        v-show="hasPermission(this.$route.name, 'Delete')">删除</span> -->
				</template>
			</el-table-column>
		</el-table>
		<Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
		<addCoupon ref="addModuleFlag" @refreshData="getData"></addCoupon>
	</div>
</template>
<script>
	import addCoupon from "./components/couponModule";
	import Pagination from "@/components/Pagination";
	import {
		dateFormats,
		getTableHeight
	} from "@/utils/util";
	import {
		fmallGoodsCoupon,
		fmallGoodsCouponDelete,
		fmallGoodsCouponDisable
	} from '@/api/api'
	import {
		loadCouponListApi,
		mangeCouponStatusApi
	} from '@/api/coupon'
	export default {
		components: {
			Pagination,
			addCoupon
		},
		name: 'goodsCouponManage',
		data() {
			return {
				isFull: false,
				tableHeight: 500,
				loadingPage: false,
				formInline: {
					page: 1,
					limit: 10,
				},
				pageparm: {
					page: 1,
					limit: 10,
					total: 0
				},
				statusList: [{
						id: 1,
						name: '是'
					},
					{
						id: 0,
						name: '否'
					}
				],
				tableData: [],
				show_devide_coupon_dialog:false,
			}
		},
		methods: {
			toDevideCoupon(){
				this.show_devide_coupon_dialog=false;
			},
			// 表格序列号
			getIndex($index) {
				// (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
				return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
			},
			setFull() {
				this.isFull = !this.isFull
				window.dispatchEvent(new Event('resize'))
			},
			//当渲染的文字超出10字后显示省略号
			customEllipsis(value) {
				value = value.replace(/<.*?>/ig, "") //把v-html的格式标签替换掉
				if (!value) return ""
				if (value.length > 10) {
					return value.slice(0, 10) + "..."
				}
				return value
			},
			addModule() {
				this.$refs.addModuleFlag.addModuleFn(null, '新增')
			},
			manageStatus(id, act) {
				let msg = ""
				if (act == "force-stop") {
					msg = "强制报废将把用户已领取的优惠券撤回不可使用，但已使用优惠券将不能撤销，请谨慎操作！"
				}
				if(act=="del"){
					msg = "确定删除该优惠券模版吗，该操作不会影响用户使用！"
				}
				let that = this
			
				this.$confirm(msg, '提醒', {
					closeOnClickModal: false
				}).then(res => {
					
					if(res=="confirm"){
					
						mangeCouponStatusApi({
							id: id,
							act_type: act
						}).then(res => {
							if (res.code == 1) {
								// row.status=status
								that.$message.success(res.msg)
								that.getData()
							} else {
								console.log("fail")
						
								that.$message.warning(res.msg)
							}
						})
					}
					
				}).catch(err => {
                    console.log(err)
				})

			},

			changeStatus(row) {
				let msg = ""
				let act = ""
				
				if (!row.status) {
					msg = "确定停止发放该优惠券吗？"
					act = "stop"
				}
				if (row.status) {
					msg = "确定开启发放该优惠券吗？开启后用户将可以在领取时段内领取优惠券"
					act = "start"
				}
				let that = this
			
				that.$confirm(msg, '提醒', {
					closeOnClickModal: false
				}).then(res => {
					
					mangeCouponStatusApi({
						id: row.id,
						act_type: act
					}).then(res => {
						if (res.code == 1) {
							// row.status=status
							that.$message.success(res.msg)
							that.getData()
						} else {
							console.log("fail")

							that.$message.warning(res.msg)
						}
					})
				}).catch(() => {

				})
			},
			handleEdit(row, flag) {
				let vm = this
				if (flag=="add-customer-coupon"){
					this.$router.push({ name: 'customerManage' });
				}
				if (flag == 'edit') {
					// if(row.status){
					// 	this.$message("请先停止发放再修改！")
					// 	return
					// }
					
					this.$confirm("修改优惠券将会影响后面领取用户的使用，已领取优惠券不受本次修改影响！如要修改请先停止发放！", '提醒', {
						 confirmButtonText: '确定',
						 cancelButtonText: '取消',
					}).then(res => {
						  vm.$refs.addModuleFlag.addModuleFn(row, '编辑')
						})
						
					
					
				} else if (flag == 'record') {
					this.$router.push({
						name: 'couponRecord',
						query: {
							coupon_id: row.id
						}
					});
				} else if (flag == 'delete') {
					vm.$confirm('确定要删除选中的信息吗？', {
						closeOnClickModal: false,
						type: 'warning'
					}).then(res => {
						fmallGoodsCouponDelete({
							id: row.id
						}).then(res => {
							if (res.code == 1) {
								vm.$message.success(res.msg)
								vm.search()
							} else {
								vm.$message.warning(res.msg)
							}
						})
					}).catch(() => {

					})
				} else if (flag == "reset") {
					this.formInline = {
						page: 1,
						limit: 10
					}
					this.pageparm = {
						page: 1,
						limit: 10,
						total: 0
					}
					this.getData()
				}
			},

			callFather(parm) {
				this.formInline.page = parm.page
				this.formInline.limit = parm.limit
				this.getData()
			},
			search() {
				this.formInline.page = 1
				this.formInline.limit = 10
				this.getData()
			},
			//获取列表
			async getData() {
				this.loadingPage = true
				loadCouponListApi(this.formInline).then(res => {
					this.loadingPage = false
					if (res.code == 1) {
						this.tableData = res.data.data_list
						
						this.pageparm.total = res.data.total;
					}
				})
			},
			// 计算搜索栏的高度
			listenResize() {
				this.$nextTick(() => {
					this.getTheTableHeight()
				})
			},
			getTheTableHeight() {
				let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
				tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
				this.tableHeight = getTableHeight(tabSelectHeight)
			}
		},
		created() {
			this.getData()
		},
		mounted() {
			// 监听页面宽度变化搜索框的高度
			window.addEventListener('resize', this.listenResize);
			this.$nextTick(() => {
				this.getTheTableHeight()
			})
		},
		unmounted() {
			// 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
		},
	}
</script>