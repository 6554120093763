<template>
	<div>
		<ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
			<el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
				label-width="auto">

				<el-form-item label="加价方式：" prop="id">
					<el-select v-model="formData.add_price_type" placeholder="请选择" style="width: 100%;" filterable
						clearable @change="handleChangeAddType" :disabled="!can_edit">
						<el-option v-for="item in add_price_type_list " :key="item.id" :label="item.name"
							:value="item.id" />
					</el-select>
				</el-form-item>
				<div v-if="formData.add_price_type==0">
					<el-form-item label="花型：" prop="flower_type">
						<el-select v-model="formData.flower_type" placeholder="请选择" style="width: 100%;" filterable
							clearable :disabled="!can_edit">
							<el-option v-for="item in flower_type_list " :key="item.id" :label="item.name"
								:value="item.id" />
						</el-select>
					</el-form-item>

					<el-form-item v-if="formData.flower_type==1" label="等级：" prop="level">
						<el-select v-model="formData.level" placeholder="请选择" style="width: 100%;" filterable clearable
							@change="handleChange" :disabled="!can_edit">
							<el-option v-for="item in level_list " :key="item.id" :label="item.name"
								:value="item.name" />
						</el-select>
					</el-form-item>


					<el-form-item label="加价：" prop="add_price">
						<el-input v-model="formData.add_price"></el-input>
					</el-form-item>


					<el-form-item label="备注：" prop="mark">
						<el-input v-model="formData.mark"></el-input>
					</el-form-item>
				</div>
				<div v-if="formData.add_price_type==1">
					 <el-form-item  label="分类：" prop="category_id">
					 	<el-select v-model="formData.category_id" placeholder="请选择" style="width: 100%;" filterable clearable
					 		@change="handleChangeCategory" :disabled="!can_edit">
					 		<el-option v-for="item in category_list " :key="item.id" :label="item.name"
					 			:value="item.id" />
					 	</el-select>
					 </el-form-item>
					 
					 <el-form-item label="加价：" prop="add_price">
					 	<el-input v-model="formData.add_price"></el-input>
					 </el-form-item>
					 
				</div>
				
				<div v-if="formData.add_price_type==2">
					 <el-form-item  label="归属分类：" prop="category_id">
					 	<el-select v-model="formData.category_id" placeholder="请选择" style="width: 100%;" filterable clearable
					 		@change="handleChangeCategory" :disabled="!can_edit">
					 		<el-option v-for="item in category_list " :key="item.id" :label="item.name"
					 			:value="item.id" />
					 	</el-select>
					 </el-form-item> 
					 <el-form-item  label="归属品种：" prop="breeds_id">
					 	<el-select v-model="formData.breeds_id" placeholder="请选择" style="width: 100%;" filterable clearable
					 		:disabled="!can_edit" >
					 		<el-option v-for="item in breeds_list " :key="item.id" :label="item.name"
					 			:value="item.id" />
					 	</el-select>
					 </el-form-item>
					 
					 <el-form-item label="加价：" prop="add_price">
					 	<el-input v-model="formData.add_price"></el-input>
					 </el-form-item>
					 
				</div>

				<!-- <el-form-item label="状态：" prop="is_active">
            <el-switch v-model="formData.is_active" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
        </el-form-item> -->
			</el-form>
			<template #footer>
				<el-button @click="handleClose" :loading="loadingSave">取消</el-button>
				<el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
			</template>
		</ly-dialog>
	</div>
</template>

<script>
	import {
		AreaPriceAddApi,
		AreaPriceEditApi,
		getAllAreaslist,
		loadLevelListApi
	} from "@/api/api";
	import {
		stationsAddApi,
		stationsEditApi
	} from "@/api/stations";
	import {
		addPlatItemApi,
		editPlatItemApi,
		
	} from "@/api/platform_add_price";
	// import {
	// 	loadLevelListApi
	// } from "@/api/level"
	
	import {loadAppListApi} from '@/api/sass_app'
	import {loadCategoyListApi,loadBreedsListApi} from '@/api/category'
	
	import LyDialog from "@/components/dialog/dialog";
	import {
		deepClone
	} from "@/utils/util"

	export default {
		name: "addDialog",
		components: {
			LyDialog
		},
		emits: ['refreshData', 'closed'],
		data() {
			return {
				dialogVisible: false,
				loadingSave: false,
				loadingTitle: '',
				can_edit:false,
				formData: {
					id: 0,
					flower_type: 1,
					level: "",
					add_price: "",
					mark: "",
					add_price_type: 0,
					category_id:0,
					breeds_id:0,
					
				},
				flower_type_list: [{
					name: '主花',
					'id': 1
				}, {
					name: '配花',
					id: 2
				}],
				level_list: [],
				super_agent_list: [],
				rules: {
					// name: [{
					// 	required: true,
					// 	message: '请输入集货站名称',
					// 	trigger: 'blur'
					// }],

					// city_code: [{
					// 	required: true,
					// 	message: '请选择集货站所在城市',
					// 	trigger: 'blur'
					// }],
					// is_active: [
					//     { required: true, message: '请选择是否启用', trigger: 'blur' }
					// ]
				},
				areaList: [],
				add_price_type_list: [{
					id: 0,
					name: '按等级加价'
				}, {
					id: 1,
					name: "按分类加价"
				}, {
					id: 2,
					name: '按品种加价'
				}],
				category_list:[],
				breeds_list:[],
				appList:[],
			}
		},
		methods: {
			
			
			handleClose() {
				this.$emit('closed')
			},
			getAllAreaslistData() {
				getAllAreaslist({
					subdistrict: 1
				}).then(res => {
					if (res.code === 1) {
						this.areaList = res.data.data
					}
				})
			},
			handleChangeAddType(e) {
				
				this.formData.category_id=0
				this.formData.breeds_id=0
			},
			handleChange(e) {
				this.formData.level = e
			},
			
			handleChangeCategory(e){
				this.formData.category_id=e
				this.locadBreeds(e)
			},
			handleOpen(item, flag) {
				this.getLevel()
				this.loadCategory()
				this.loadingTitle = flag
				this.dialogVisible = true
				if (item) {
					this.can_edit=false
					this.formData = deepClone(item)
					if(this.formData.breeds_id>0){
						this.locadBreeds(this.formData.category_id)
					}
					
				} else {
					this.can_edit=true
					// this.rules.password = [
					//     { required: true, message: '请输入密码', trigger: 'blur' }
					// ]
				}

			},
			
			locadBreeds(category_id){
				loadBreedsListApi({category_id:category_id,limit:999,status:1}).then(res=>{
					if(res.code==1){
						
						this.breeds_list=res.data.data_list
					}
				})
			},
            loadCategory(){
				loadCategoyListApi({limit:999,status:1}).then(res=>{
					if(res.code==1){
						this.category_list=res.data.data_list
						
					}
					
				})
			},
			getLevel() {
				loadLevelListApi({limit:99,status:1}).then(res => {
					if (res.code == 1) {

						this.level_list = res.data.data_list
					}
				})
			},
			submitData() {
				this.$refs['rulesForm'].validate(obj => {
					if (obj) {
						this.loadingSave = true
						let param = {
							...this.formData
						}
						if (this.formData.id > 0) {
							editPlatItemApi(param).then(res => {
								this.loadingSave = false
								if (res.code == 1) {
									this.$message.success(res.msg)
									this.dialogVisible = false;
									this.handleClose()
									this.$emit('refreshData')
								} else {
									this.$message.warning(res.msg)
								}
							})
						} else {
							addPlatItemApi(param).then(res => {
								this.loadingSave = false
								if (res.code == 1) {
									this.$message.success(res.msg)
									this.handleClose()
									this.$emit('refreshData')
								} else {
									this.$message.warning(res.msg)
								}
							})
						}

					}
				})
			},
		}
	}
</script>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 128px;
		height: 128px;
		line-height: 128px;
		text-align: center;
	}

	.avatar {
		width: 128px;
		height: 128px;
		display: block;
	}
</style>