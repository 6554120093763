import axios from "axios";
import {
  ajaxGet,
  ajaxPost,

} from "./request";
import { url } from "./url";


export const updateAgentSendTypeApi = (params) =>ajaxPost({ url: `agent/update/sendtype`, params });
//打包费设置

export const addPackagingFeeApi = (params) =>ajaxPost({ url: `agent/packaging/fee/add`, params });

  