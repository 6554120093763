<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="640px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <!--<el-form-item label="管理员编号：" prop="id">-->
                    <!--<el-input v-model.trim="formData.id" style="width: 300px"></el-input>-->
                <!--</el-form-item>-->
                <el-form-item label="管理员名称：" prop="name">
                    <el-input v-model="formData.name" style="width: 250px;"></el-input>
                </el-form-item>
				<el-form-item label="真实姓名：" prop="real_name">
				    <el-input v-model.trim="formData.real_name" style="width: 250px;"></el-input>
				</el-form-item>
				<el-form-item label="联系电话：" prop="phone">
				    <el-input v-model.trim="formData.phone" style="width: 250px;" :readonly="formData.is_sys"></el-input>
					<p style="margin-left: 8px;">电话号码将作为登录账号</p>
				</el-form-item>
				
				
				<view v-if="formData.id==0">
					<el-form-item  label="登录密码：" prop="password">
					        <el-input v-model.trim="formData.password" placeholder="新的登录密码" style="width: 250px;"></el-input>
					    </el-form-item>
				</view>
				
				<el-form-item label="角色：" prop="role_id_list">
				    <el-checkbox-group v-model="formData.role_id_list">
				        <el-checkbox  :label="item.id" v-for="(item,index) in rolelist" :key="index">{{item.name}}</el-checkbox>
					    
					  
				    </el-checkbox-group>
				</el-form-item>
				
				
				<view v-if="edit_type!='重置密码'">
					
				</view>
                
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {apiSystemUserAdd,apiSystemUserEdit,apiSystemRole,apiSystemDept} from "@/api/api";
    import {addAdminApi,updateAdminApi,resetAdminPwdApi,newAdminApi,loadRoleListApi} from "@/api/system";
    import LyDialog from "@/components/dialog/dialog";
    import XEUtils from "xe-utils";
    import {deepClone} from "@/utils/util";
    export default {
        components: {LyDialog},
        emits: ['refreshData'],
        name: "addAdmin",
        data() {
            return {
				edit_type:'',
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
					id:0,
					name:"",
                    real_name:'',
                    account:'',
                    password:'',
					phone:"",
                    dept:'',
                    role_id_list:[],
                    status:true,
                },
                rules:{
                    name: [
                        {required: true, message: '请输入管理员名称',trigger: 'blur'}
                    ],
                    role_id_list: [
                        {required: true, message: '请选择角色',trigger: 'blur'}
                    ],
                    // dept: [
                    //     {required: true, message: '请选择部门',trigger: 'blur'}
                    // ],
                    account: [
                        {required: true, message: '请输入管理员登录账号',trigger: 'blur'}
                    ],
                    is_active: [
                        {required: true, message: '请选择是否启用',trigger: 'blur'}
                    ]
                },
                rolelist:[],
                options:[],
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
				
                this.formData = {
					id:0,
                   real_name:'',
                   
                   password:'',
                   phone:"",
                  
                   role_id_list:[],
                   status:true,
                   
                }
            },
            addAdminFn(item,flag) {
                this.getapiSystemRole()
                
                this.loadingTitle=flag
                this.dialogVisible=true
               
				this.edit_type=flag
                // if(item && item.dept) {
                //     item.dept = item.dept.split(" ")
                // }
                if(item){
                    this.formData = deepClone(item)
					// this.formData.is_superuser=JSON.stringify(item.is_superuser)
                }

                this.formData.role = item?item.role:[]
            },
			resetPwd(){
				resetAdminPwdApi({id:this.formData.id,password:this.formData.password}).then(res=>{
					if(res.code==1){
						 this.$message.success(res.msg)
						  this.handleClose()
						
					}else{
						this.$message.warning(res.msg)
					}
				})
			},
            submitData() {
				if(this.edit_type=="重置密码"){
					this.resetPwd()
					return
				}
				
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        console.log("")
                        

                        if(this.formData.nickname=="" || this.formData.nickname== undefined || this.formData.nickname.length<=0 || this.formData.nickname=='""'){
                            param.nickname = this.formData.name
                        }
						if(this.formData.id<=0){
							newAdminApi(param).then(res=>{
								if(res.code==1){
									 this.$message.success(res.msg)
									 this.handleClose()
									 this.$emit('refreshData')
								}else{
									this.$message.warning(res.msg)
								}
								 this.loadingSave=false
							})
						}else{
							newAdminApi(param).then(res=>{
								if(res.code==1){
									 this.$message.success(res.msg)
									 this.handleClose()
									 this.$emit('refreshData')
								}else{
									this.$message.warning(res.msg)
								}
								this.loadingSave=false
							})
						}
						
                        // if(this.formData.id){
                        //     if(param.post.length<1){
                        //         delete param.post
                        //     }
                        //     apiSystemUserEdit(param).then(res=>{
                        //         this.loadingSave=false
                        //         if(res.code ==2000) {
                        //             this.$message.success(res.msg)
                        //             this.handleClose()
                        //             this.$emit('refreshData')
                        //         } else {
                        //             this.$message.warning(res.msg)
                        //         }
                        //     })
                        // }else{
                        //     apiSystemUserAdd(param).then(res=>{
                        //         this.loadingSave=false
                        //         if(res.code ==2000) {
                        //             this.$message.success(res.msg)
                        //             this.handleClose()
                        //             this.$emit('refreshData')
                        //         } else {
                        //             this.$message.warning(res.msg)
                        //         }
                        //     })
                        // }

                    }
                })
            },
            getapiSystemRole(){
                loadRoleListApi({page:1,limit:999,status:1}).then(res=>{
                    if(res.code ==1) {
                        this.rolelist = res.data.data_list
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getapiSystemDept(){
                apiSystemDept({page:1,limit:999}).then(res=>{
                    if(res.code ==2000) {
                        this.options = XEUtils.toArrayTree(res.data.data, { parentKey: 'parent' })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },


        }
    }
</script>

